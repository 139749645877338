import './Header.scss';
import React from 'react';
import Links from '../Links/Links';
import MenuIcon from '../../assets/menu.svg';
import { MenuLink } from '../Landing/Landing';
import { phones } from '../../constants/Contacts.json';
import { formatPhone } from '../../heleprs';
import { Link } from 'gatsby';
import cn from 'classnames';
import { LangEnum, routesEnum } from '../../types';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

interface Props {
  links: MenuLink[];
  isLinksShown?: boolean;
  onHamburgerClick?: () => void;
  onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  headerRef?: React.MutableRefObject<HTMLButtonElement>;
  logoSrc: string;
  lang: LangEnum;
  className?: string;
  uaLink?: string;
  ruLink?: string;
}

const Header = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      links,
      isLinksShown,
      onHamburgerClick,
      onClick,
      logoSrc,
      lang,
      className = '',
      uaLink,
      ruLink
    },
    ref
  ) => {
    const formattedPhone = formatPhone(phones[0]);

    const handlePhoneClick = () => {
      trackCustomEvent({
        category: 'Phone-click',
        action: 'phone-click'
      });
    };

    return (
      <header className={`Header position-sticky w-100 ${className}`} ref={ref}>
        <div className='Header__menu d-flex align-items-center mx-auto justify-content-between'>
          <div className='Header__logo w-100 h-auto'>
            <img src={logoSrc} className='w-100 h-auto' alt='logo' />
          </div>
          <Links
            links={links}
            onClick={onClick}
            lang={lang}
            className='d-none d-xl-flex'
          />
          <div className='d-flex align-items-center'>
            <div className='Header__phone fw-bold w-100 text-center Header__phone--sm'>
              <a href={`tel:${phones[0]}`} onClick={handlePhoneClick}>
                {formattedPhone}
              </a>
            </div>
            <div className='mx-2'>
              <Link
                to={uaLink || routesEnum.home}
                className={cn('Header__link', {
                  'Header__link--active': lang === LangEnum.uk
                })}
              >
                UA
              </Link>
              <span className='mx-1'>|</span>
              <Link
                to={ruLink || routesEnum.langHome.replace(':lang', LangEnum.ru)}
                className={cn('Header__link', {
                  'Header__link--active': lang === LangEnum.ru
                })}
              >
                RU
              </Link>
            </div>
            <button
              className='Header__hamburger d-flex d-xl-none position-relative'
              onClick={onHamburgerClick}
            >
              <MenuIcon />
            </button>
          </div>
        </div>
        <div
          className='Header__phone fw-bold w-100 text-center'
          onClick={handlePhoneClick}
        >
          <a href={`tel:${phones[0]}`}>{formattedPhone}</a>
        </div>
      </header>
    );
  }
);

export default Header;
