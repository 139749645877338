import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useDebounce, useLockScroll } from '../../hooks';
import CloseIcon from '../../assets/close.svg';
import ArrowRight from '../../assets/chevron-forward.svg';
import './Viewer.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Portal from '../Portal/Portal';
import { PortalEnum } from '../../types';

interface iProps {
  isOpened: boolean;
  onClose: () => void;
  className?: string;
  initialIndex: number;
  images: any[];
}

const Viewer: React.FC<iProps> = ({
  isOpened,
  onClose,
  className,
  initialIndex,
  images
}) => {
  const slidesRef = useRef(null);
  const debouncedIsOpened = useDebounce(isOpened, 200);
  const isViewerOpened = isOpened || debouncedIsOpened;
  const [activeIndex, setActiveIndex] = useState<number>(initialIndex);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleNext = () => {
    setIsAnimating(false);
    setActiveIndex(prev => {
      if (prev === images.length - 1) setActiveIndex(0);
      return prev + 1;
    });
  };
  const handlePrev = () => {
    setIsAnimating(false);
    setActiveIndex(prev => {
      if (prev === 0) setActiveIndex(images.length - 1);
      return prev - 1;
    });
  };

  useLockScroll(isOpened);

  useEffect(() => {
    setActiveIndex(initialIndex);
  }, [initialIndex]);

  useEffect(() => {
    setIsAnimating(true);
  }, [activeIndex]);

  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Left':
        case 'ArrowLeft':
          handlePrev();
          break;
        case 'Right':
        case 'ArrowRight':
          handleNext();
          break;
        case 'Esc':
        case 'Escape':
          onClose();
          break;
        default:
          return;
      }
    };

    if (isOpened) {
      window.addEventListener('keydown', handler);
    }

    return () => {
      window.removeEventListener('keydown', handler);
    };
    // eslint-disable-next-line
  }, [isOpened]);

  return (
    <Portal id={PortalEnum.Viewer}>
      {isViewerOpened ? (
        <div
          className={cn(
            'Viewer w-100 h-100 d-flex justify-content-between flex-column overflow-hidden',
            className,
            {
              fadeIn: isOpened,
              fadeOut: !isOpened
            }
          )}
        >
          <div className='Viewer__crossContainer position-absolute d-flex justify-content-end'>
            <button onClick={onClose}>
              <CloseIcon />
            </button>
          </div>
          <div className='w-100 d-flex align-items-center flex-grow-1'>
            <div
              ref={slidesRef}
              className='Viewer__slides d-flex justify-content-between align-items-center w-100'
              onClick={(e: React.MouseEvent) => {
                if (e.target === slidesRef.current) {
                  onClose();
                }
              }}
            >
              <button
                className='Viewer__arrow Viewer__arrow_left'
                onClick={handlePrev}
              >
                <ArrowRight />
              </button>
              {images[activeIndex] && (
                <LazyLoadImage
                  alt={images[activeIndex]?.node.id}
                  src={images[activeIndex]?.node.secure_url}
                  className={cn('Viewer__slide d-block', {
                    'Viewer__slide--active': isAnimating
                  })}
                />
              )}
              <button
                className='Viewer__arrow Viewer__arrow_right'
                onClick={handleNext}
              >
                <ArrowRight />
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </Portal>
  );
};

export default Viewer;
