import React from 'react';
import cn from 'classnames';
import './Links.scss';
import { MenuLink } from '../Landing/Landing';
import { LangEnum } from '../../types';
import { Link } from 'gatsby';

interface Props {
  links: MenuLink[];
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  lang: LangEnum;
}

const Links: React.FC<Props> = ({ links, className, onClick, lang }) => {
  return (
    <div className={cn('Links', className)}>
      {links.map(link =>
        link.href.startsWith('/') ? (
          <Link
            to={lang === LangEnum.uk ? link.href : `/${lang}${link.href}`}
            className='Links__item'
            key={link.href}
            data-scrollto={link.href}
          >
            {link.label[lang]}
          </Link>
        ) : (
          <div
            className='Links__item'
            key={link.href}
            onClick={onClick}
            data-scrollto={link.href}
          >
            {link.label[lang]}
          </div>
        )
      )}
    </div>
  );
};

export default Links;
