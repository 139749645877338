import React from 'react';
import Links from '../Links/Links';
import cn from 'classnames';
import './SlideMenu.scss';
import { MenuLink } from '../Landing/Landing';
import CloseIcon from '../../assets/close.svg';
import Portal from '../Portal/Portal';
import { useLockScroll } from '../../hooks';
import { LangEnum, PortalEnum } from '../../types';

interface Props {
  isOpened: boolean;
  links: MenuLink[];
  onClose: (e: React.MouseEvent) => void;
  lang: LangEnum;
}

const SlideMenu = React.forwardRef<HTMLDivElement, Props>(
  ({ isOpened, links, onClose, lang }, ref) => {
    useLockScroll(isOpened);

    return (
      <Portal id={PortalEnum.SlideMenu}>
        <div className={'SlideMenu'}>
          <div
            className={cn('SlideMenu__overlay position-fixed', {
              'SlideMenu__overlay--opened': isOpened
            })}
          />
          <div
            ref={ref}
            className={cn('SlideMenu__menu w-100 h-100 position-fixed p-3', {
              'SlideMenu__menu--opened': isOpened
            })}
          >
            <div className='SlideMenu__close'>
              <div onClick={onClose} className='SlideMenu__closeIcon'>
                <CloseIcon />
              </div>
            </div>
            <Links
              lang={lang}
              links={links}
              onClick={onClose}
              className='Links_SlideMenu'
            />
          </div>
        </div>
      </Portal>
    );
  }
);

export default SlideMenu;
