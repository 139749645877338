import { detectMobile } from '../heleprs';

export const useScrollToElement = (
  headerRef: React.RefObject<HTMLDivElement>
) => {
  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);

    if (!element || !headerRef.current) return;
    window.scrollTo({
      top:
        element.getBoundingClientRect().top +
        (window.scrollY ||
          window.pageYOffset ||
          document.documentElement.scrollTop) -
        headerRef.current.offsetHeight,
      behavior: detectMobile() || id === 'contacts' ? 'auto' : 'smooth'
    });
  };

  const handleLinkClick = (e: React.MouseEvent) => {
    const link = (e.target as HTMLElement).getAttribute('data-scrollto');
    if (link) scrollToElement(link);
  };

  return {
    handleLinkClick
  };
};
