import React, { ReactNode } from 'react';
import cn from 'classnames';
import './Section.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface Props {
  imagePosition?: 'right' | 'left';
  title: string;
  text?: string;
  img?: string;
  isGray?: boolean;
  children?: ReactNode;
  mediaContent?: JSX.Element;
  className?: string;
  id?: string;
  imgMaxHeight?: number;
}

const Section: React.FC<Props> = ({
  imagePosition = 'left',
  title,
  text,
  img,
  isGray = false,
  children,
  className,
  imgMaxHeight,
  mediaContent,
  id
}) => {
  const textParts = (text && text.split('\n')) || [];

  return (
    <section
      className={cn('Section', className, {
        Section_gray: isGray
      })}
      id={id}
    >
      <div className='Section__container d-flex flex-column flex-lg-row justify-content-center mx-auto px-3 px-lg-4 w-100'>
        <div className='row'>
          <div
            className={cn('col-12 col-lg-6 d-flex flex-column mb-3 mb-lg-0', {
              'order-lg-2': imagePosition === 'right'
            })}
          >
            <div className='Section__title mb-3'>{title}</div>
            {mediaContent ||
              (img && (
                <LazyLoadImage
                  effect='blur'
                  wrapperClassName='d-flex flex-grow-1 justify-content-center align-items-center flex-column'
                  className='Section__image flex-grow-1'
                  alt={img}
                  src={img}
                  style={{
                    maxHeight: imgMaxHeight || 'unset',
                    objectFit: imgMaxHeight ? 'contain' : 'cover'
                  }}
                />
              ))}
          </div>
          <div className='col col-12 col-lg-6'>
            <div className='Section__title mb-3 Section__title--lg mb-3-lg'>
              {title}
            </div>
            {children ||
              textParts?.map((str, i) => (
                <div
                  className='Section__text'
                  style={{
                    marginBottom: i !== textParts.length - 1 ? 16 : 'unset'
                  }}
                  key={i}
                >
                  {str}
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section;
