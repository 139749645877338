import Localize from '../../constants/Localize.json';
import { LangEnum } from '../../types';
import { FormData } from './Form';

enum MaxLength {
  email = 254,
  name = 255,
  message = 500
}

const isNotEmpty = (v: string) => !!v;
const isPhone = (v: string) => {
  // eslint-disable-next-line
  if (v.startsWith('+')) return /^\+\d{12,17}/g.test(v.trim());
  // eslint-disable-next-line
  return /^\+?\d{10,17}/g.test(v.trim());
};
const isEmail = (email: string) => {
  // eslint-disable-next-line
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email.trim());
};

const maxLength = (length: number) => (v: string) => {
  return v.trim().length <= length;
};

const nameMaxLength = maxLength(MaxLength.name);
const emailMaxLength = maxLength(MaxLength.email);
const messageMaxLength = maxLength(MaxLength.message);

const checkers = {
  name: [isNotEmpty, nameMaxLength],
  email: [isEmail, emailMaxLength],
  phone: [isNotEmpty, isPhone],
  message: [messageMaxLength]
};

const getErrorMessage = (
  ch: (v: string) => boolean,
  lang: LangEnum,
  key: keyof FormData
) => {
  switch (ch) {
    case isPhone:
      return Localize[lang].errorPhone;
    case isNotEmpty:
      return Localize[lang].errorRequired;
    case isEmail:
      return Localize[lang].errorEmail;
    case nameMaxLength:
    case emailMaxLength:
    case messageMaxLength:
      return `${Localize[lang].maxLength} ${
        MaxLength[key as keyof typeof MaxLength]
      }`;
    default:
      return '';
  }
};

export const trimFormData = <T>(data: T) =>
  Object.entries(data).reduce((acc: T, [key, value]) => {
    acc[key as keyof T] = value.trim();
    return acc;
  }, {} as T);

export const validateField = (
  key: keyof FormData,
  value: string,
  lang: LangEnum,
  setErrors: (fn: (prev: Partial<FormData>) => Partial<FormData>) => void
) => {
  const errors: string[] = checkers[key]
    .map((ch: (v: string) => boolean) => {
      if (key === 'email' && !value) return false;
      if (!ch(value)) return getErrorMessage(ch, lang, key);
      return false;
    })
    .filter((v: string) => !!v);
  setErrors(prev => ({
    ...prev,
    [key]: errors[0]
  }));
  return errors[0];
};
