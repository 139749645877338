import React, { useState, useRef } from 'react';
import './Gallery.scss';
import Viewer from '../Viewer/Viewer';
import { CloudinaryEdge } from '../../types';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import PlusIcon from '../../assets/add-circle-outline.svg';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { useMedia } from '../../hooks';

interface Props {
  images: CloudinaryEdge[];
  title: string;
}

const Gallery: React.FC<Props> = ({ images, title }) => {
  const initialActiveIndex = useRef(0);
  const [isSliderOpened, setIsSliderOpened] = useState(false);
  const [isExpdaned, setIsExpanded] = useState(false);
  const isTablet = useMedia(['(min-width: 768px)'], [true], undefined);

  const handleClick = (index: number) => () => {
    if (!isTablet) return;
    trackCustomEvent({
      category: 'Gallery',
      action: 'open-view'
    });
    setIsSliderOpened(true);
    initialActiveIndex.current = index;
  };

  const handleCloseSlider = () => {
    setIsSliderOpened(false);
  };

  const expandGallery = () => {
    trackCustomEvent({
      category: 'Gallery',
      action: 'more-photos'
    });
    setIsExpanded(true);
  };

  return (
    <section className='Gallery' id='gallery'>
      <div className='Gallery__container'>
        <div className='Gallery__title'>{title}</div>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 650: 2, 900: 3 }}>
          <Masonry gutter='12px'>
            {images.slice(0, 11).map((image, index: number) => (
              <div
                onClick={handleClick(index)}
                key={`${image.node.id}`}
                className='Gallery__imageContainer'
              >
                <img
                  alt={image.node.id}
                  src={image.node.secure_url}
                  className='Gallery__image'
                  width={600}
                  height={1067}
                />
              </div>
            ))}
            {!isExpdaned ? (
              <button
                onClick={expandGallery}
                className='Gallery__toggler d-flex align-items-center justify-content-center w-100 flex-column'
              >
                <PlusIcon />
                <div>Більше фото</div>
              </button>
            ) : (
              images.slice(11).map((image, index: number) => (
                <div
                  onClick={handleClick(index)}
                  key={`${image.node.id}`}
                  className='Gallery__imageContainer'
                >
                  <img
                    alt={image.node.id}
                    src={image.node.secure_url}
                    className='Gallery__image d-block'
                    width={600}
                    height={1067}
                  />
                </div>
              ))
            )}
          </Masonry>
        </ResponsiveMasonry>
        <Viewer
          isOpened={isSliderOpened}
          onClose={handleCloseSlider}
          initialIndex={initialActiveIndex.current}
          images={images}
        />
      </div>
    </section>
  );
};
export default Gallery;
