import React, { useEffect } from 'react';
import Portal from '../Portal/Portal';
import cn from 'classnames';
import './Notification.scss';
import { PortalEnum } from '../../types';

interface Props {
  isShown: boolean;
  onClose: () => void;
  isError: boolean;
  text: string;
}

const Notification: React.FC<Props> = ({ isShown, onClose, isError, text }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      isShown && onClose();
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, [isShown]);

  return (
    <Portal id={PortalEnum.Notification}>
      {!isShown ? null : (
        <div
          className={cn(
            'Notification position-fixed display-flex align-items-center',
            {
              Notification_error: isError,
              Notification_success: !isError
            }
          )}
          onClick={onClose}
        >
          <div className='Notification__content'>
            <div className='Notification__text'>{text}</div>
          </div>
        </div>
      )}
    </Portal>
  );
};

export default Notification;
