import { LazyLoadImage } from 'react-lazy-load-image-component';
import React, { useRef, useState } from 'react';
import './Video.scss';
import Play from '../assets/play.svg';

interface Props {
  poster: string;
  url: string;
}

export default function Video({ poster, url }: Props) {
  const [isPosterShown, setIsPosterShown] = useState(true);
  const ref = useRef<HTMLVideoElement>(null);

  const onClickPlay = () => {
    setIsPosterShown(false);
    const videos = Array.from(document.querySelectorAll('video'));
    if (!!videos.length) {
      videos.forEach(v => {
        v.currentTime = 0;
        v.pause();
      });
    }
    ref.current?.play();
  };

  const onEnded = () => {
    if (ref.current) {
      setIsPosterShown(true);
      ref.current.pause();
    }
  };

  const onPause = () => {
    if (ref.current?.currentTime === 0) {
      setIsPosterShown(true);
    }
  };

  return (
    <div className='ratio ratio-16x9 position-relative Video'>
      <video
        controls
        poster={poster}
        controlsList='nodownload'
        className='Video'
        ref={ref}
        onEnded={onEnded}
        onPause={onPause}
      >
        <source src={url} />
      </video>
      {isPosterShown && (
        <>
          <LazyLoadImage
            effect='blur'
            src={poster + '?fm=webp&w=368'}
            className='position-absolute Video__poster'
          />
          <button
            className='position-absolute Video__play w-100 h-100'
            onClick={onClickPlay}
          >
            <Play style={{ zIndex: 2, position: 'relative' }} className='' />
          </button>
        </>
      )}
    </div>
  );
}
