import React, { useCallback, useEffect, useRef } from 'react';
import Hero from '../Hero/Hero';
import SlideMenu from '../SlideMenu/SlideMenu';
import Section from '../Section/Section';
import Blocks from '../../constants/Blocks.json';
import Links from '../../constants/Links.json';
import Advantages from '../Advantages/Advantages';
import SEO from '../Seo';
import { useScrollToElement } from '../../hooks';
import { graphql, useStaticQuery } from 'gatsby';
import Gallery from '../Gallery/Gallery';
import CallForm from '../CallForm/CallForm';
import Steps from '../Steps/Steps';
import Contacts from '../Contacts/Contacts';
import Footer from '../Footer/Footer';
import Lift from '../Lift/Lift';
import Header from '../Header/Header';
import CallWidget from '../CallWidget/CallWidget';
import { LangEnum } from '../../types';
import { useSlideMenu } from '../../hooks';
import '../../styles/styles.scss';
import Video from '../Video';

export interface MenuLink {
  label: { uk: string; ru: string };
  href: string;
}

export interface Props {
  keywords: string;
  description: string;
  lang: LangEnum;
  title: string;
}

const Landing: React.FC<Props> = ({ keywords, description, lang, title }) => {
  const headerRef = useRef<HTMLDivElement>(null);

  const {
    slideMenuRef,
    isSlideMenuOpened,
    closeSlideMenu,
    openSlideMenu
  } = useSlideMenu();

  const { handleLinkClick } = useScrollToElement(headerRef);

  const onSlideMenuClose = useCallback(
    (e: React.MouseEvent) => {
      closeSlideMenu();
      handleLinkClick(e);
    },
    [closeSlideMenu, handleLinkClick]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = useStaticQuery(graphql`
    query CloudinaryImage {
      advantages: cloudinaryMedia(public_id: { eq: "granit/advantages" }) {
        secure_url
      }
      form: cloudinaryMedia(public_id: { eq: "granit/form" }) {
        secure_url
      }
      gallery: allCloudinaryMedia(
        filter: { public_id: { regex: "/gallery/" } }
        sort: { order: DESC, fields: created_at }
      ) {
        edges {
          node {
            id
            secure_url
            public_id
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO
        title={title}
        keywords={keywords}
        description={description}
        lang={lang}
      />
      <Header
        links={Links}
        onHamburgerClick={openSlideMenu}
        onClick={handleLinkClick}
        ref={headerRef}
        logoSrc='https://res.cloudinary.com/granitmontazh/image/upload/v1677518990/granit/logo.png'
        lang={lang}
      />
      <main>
        <Hero
          backgroundSrc='https://res.cloudinary.com/granitmontazh/image/upload/v1606939496/granit/hero.jpg'
          lang={lang}
        />
        <SlideMenu
          lang={lang}
          links={Links}
          isOpened={isSlideMenuOpened}
          ref={slideMenuRef}
          onClose={onSlideMenuClose}
        />
        <Section
          title={Blocks.manufactory.title[lang]}
          text={Blocks.manufactory.text[lang]}
          img='https://res.cloudinary.com/granitmontazh/image/upload/v1606939569/granit/manufactory.jpg'
          isGray
          id={Blocks.manufactory.key}
        />
        <Section
          mediaContent={<Video poster='/poster.webp' url='/granit.mp4' />}
          imagePosition='right'
          title={Blocks.installation.title[lang]}
          text={Blocks.installation.text[lang]}
          id={Blocks.installation.key}
        />
        <Section
          title={Blocks.landscaping.title[lang]}
          text={Blocks.landscaping.text[lang]}
          img='https://res.cloudinary.com/granitmontazh/image/upload/v1606939630/granit/landscaping.jpg'
          isGray
          id={Blocks.landscaping.key}
        />
        <Section
          title={Blocks.advantages.title[lang]}
          children={<Advantages lang={lang} />}
          img='https://res.cloudinary.com/granitmontazh/image/upload/v1606939653/granit/advantages.png'
          imgMaxHeight={500}
          className='Section--Advantages'
        />
        <Section
          title={Blocks.warranty.title[lang]}
          text={Blocks.warranty.text[lang]}
          img='https://res.cloudinary.com/granitmontazh/image/upload/v1606939689/granit/warranty.png'
          imagePosition='right'
          isGray
          className='Section--Warranty '
          id={Blocks.warranty.key}
        />
        <CallForm imgUrl={data.form.secure_url} lang={lang} />
        <Gallery
          images={data.gallery.edges}
          title={Blocks.gallery.title[lang]}
        />
        <Steps
          images={[
            'https://res.cloudinary.com/granitmontazh/image/upload/q_auto,f_auto/v1606939900/granit/steps/step-1_badejk.png',
            'https://res.cloudinary.com/granitmontazh/image/upload/q_auto,f_auto/v1606939900/granit/steps/step-2_ymyiya.png',
            'https://res.cloudinary.com/granitmontazh/image/upload/q_auto,f_auto/v1606939900/granit/steps/step-3_kttah9.png',
            'https://res.cloudinary.com/granitmontazh/image/upload/q_auto,f_auto/v1606939900/granit/steps/step-4_tnsigu.png',
            'https://res.cloudinary.com/granitmontazh/image/upload/q_auto,f_auto/v1606939900/granit/steps/step-5_n6yvz7.png',
            'https://res.cloudinary.com/granitmontazh/image/upload/q_auto,f_auto/v1606939900/granit/steps/step-6_nekw72.png'
          ]}
          lang={lang}
        />
        <Contacts formBackgroundUrl={data.form.secure_url} lang={lang} />
      </main>
      <Footer links={Links} onClick={handleLinkClick} lang={lang} />
      <Lift />
      <CallWidget lang={lang} />
    </>
  );
};
export default Landing;
