import React, { useState, useEffect } from 'react';
import './Contacts.scss';
import MailIcon from '../../assets/mail-outline.svg';
import LocationIcon from '../../assets/location-outline.svg';
import PhoneIcon from '../../assets/call-outline.svg';
import LogoFacebook from '../../assets/logo-facebook.svg';
import LogoInstagram from '../../assets/logo-instagram.svg';
import ContactsData from '../../constants/Contacts.json';
import { formatPhone } from '../../heleprs';
import Form from '../Form/Form';
import { LangEnum } from '../../types';
import Blocks from '../../constants/Blocks.json';

interface Props {
  formBackgroundUrl: string;
  lang: LangEnum;
}

const Contacts: React.FC<Props> = ({ formBackgroundUrl, lang }) => {
  const [mapSrc, setMapSrc] = useState('');
  const items = [
    {
      text: ContactsData.address[lang],
      link: ContactsData.googleMapsLink,
      icon: <LocationIcon />
    },
    {
      text: ContactsData.email,
      link: `mailto:${ContactsData.email}`,
      icon: <MailIcon />
    },
    {
      text: formatPhone(ContactsData.phones[0]),
      link: `tel:${ContactsData.phones[0]}`,
      icon: <PhoneIcon />
    },
    {
      text: formatPhone(ContactsData.phones[1]),
      link: `tel:${ContactsData.phones[1]}`,
      icon: <PhoneIcon />
    },
    {
      text: ContactsData.facebook,
      link: `https://www.facebook.com/${ContactsData.facebook}/`,
      icon: <LogoFacebook />
    },
    {
      text: ContactsData.instagram,
      link: `https://www.instagram.com/${ContactsData.instagram}/`,
      icon: <LogoInstagram />
    }
  ];

  useEffect(() => {
    const mountMap = () => {
      if (
        (window.scrollY ||
          window.pageYOffset ||
          document.documentElement.scrollTop) >= 1000
      ) {
        setMapSrc(ContactsData.googleMapsEmbeded);
      }
    };
    if (!mapSrc) {
      document.addEventListener('scroll', mountMap);
    }
    return () => {
      document.removeEventListener('scroll', mountMap);
    };
  }, [mapSrc]);

  return (
    <section className='Contacts' id='contacts'>
      <div className='Contacts__title'>{Blocks.contacts.title[lang]}</div>
      <div className='Contacts__container'>
        <div className='Contacts__details'>
          {mapSrc && (
            <iframe
              src={mapSrc}
              width='100%'
              height='250'
              className='Contacts__map'
              frameBorder='0'
              allowFullScreen={false}
              title='map'
            ></iframe>
          )}
          <div className='Contacts__items'>
            {items.map(({ text, link, icon }) => {
              return (
                <div key={text}>
                  <a
                    className='Contacts__link'
                    href={link}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    <div className='Contacts__icon'>{icon}</div>
                    <div className='Contacts__itemText'>{text}</div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className='Contacts__form'
          style={{
            backgroundImage: `linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6)), url('${formBackgroundUrl}')`,
            backgroundPosition: 'center top',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed'
          }}
        >
          <Form lang={lang} id='contacts-form' />
        </div>
      </div>
    </section>
  );
};

export default Contacts;
