import React from 'react';
import { LangEnum } from '../../types';
import { MenuLink } from '../Landing/Landing';
import Links from '../Links/Links';
import './Footer.scss';
import Localize from '../../constants/Localize.json';
import Contacts from '../../constants/Contacts.json';

interface Props {
  links: MenuLink[];
  onClick: (e: React.MouseEvent<Element, MouseEvent>) => void;
  lang: LangEnum;
}

const Footer: React.FC<Props> = ({ links, onClick, lang }) => {
  return (
    <footer className='Footer'>
      <div className='Footer__container p-3 mx-auto px-lg-4 py-lg-3'>
        <Links
          links={links}
          onClick={onClick}
          className='Links--Footer flex-column align-items-center justify-content-center d-flex flex-lg-row'
          lang={lang}
        />
        <div className='Footer__copyright'>
          © 2020 {Localize[lang].copyright}
          <br />
          {Contacts.name[lang]}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
