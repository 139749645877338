import React from 'react';
import './Hero.scss';
import Form from '../Form/Form';
import { LangEnum } from '../../types';
import Localize from '../../constants/Localize.json';

interface Props {
  backgroundSrc: string;
  lang: LangEnum;
}

const Hero: React.FC<Props> = ({ backgroundSrc, lang }) => {
  return (
    <section
      className='Hero d-flex align-items-center justify-content-center postition-relative py-4 py-0 px-4'
      style={{
        backgroundImage: `url('${backgroundSrc}')`,
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <div className='Hero__content px-3 px-lg-4 d-lg-flex justify-content-lg-between mx-auto w-100'>
        <div
          className='Hero__title'
          dangerouslySetInnerHTML={{ __html: Localize[lang].heroTitle }}
        />
        <div className='Hero__form justify-content-center w-100 mx-auto mx-lg-0 flex-shrink-0'>
          <Form lang={lang} id='hero-form' />
        </div>
      </div>
    </section>
  );
};

export default Hero;
