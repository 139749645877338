import React from 'react';
import { LangEnum } from '../../types';
import { steps as StepsData } from '../../constants/Blocks.json';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './Steps.scss';

interface Props {
  images: string[];
  lang: LangEnum;
}

const Steps: React.FC<Props> = ({ images, lang }) => {
  return (
    <section className='Steps'>
      <div className='Steps__container w-100 mx-auto'>
        <div className='Steps__title mb-3 mb-lg-4 text-center'>
          {StepsData.title[lang]}
        </div>
        <div className='Steps__items d-sm-grid d-lg-flex justify-content-lg-between flex-lg-wrap'>
          {StepsData.items[lang].map((step: string, i: number) => (
            <div
              className='Steps__item mb-4 d-flex flex-column align-items-center'
              key={step}
            >
              <LazyLoadImage
                alt={`step-${i + 1}`}
                src={images[i]}
                width={150}
                height={150}
              />
              <div>{step}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Steps;
