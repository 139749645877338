import React from 'react';
import SpeedometrIcon from '../../assets/speedometer.svg';
import CalculatorIcon from '../../assets/calculator.svg';
import CarIcon from '../../assets/car.svg';
import MaterialsIcon from '../../assets/logo-buffer.svg';
import { advantages as AdvantagesData } from '../../constants/Blocks.json';
import './Advantages.scss';
import { LangEnum } from '../../types';

interface Props {
  lang: LangEnum;
}

const icons = [
  <SpeedometrIcon />,
  <CarIcon />,
  <MaterialsIcon />,
  <CalculatorIcon />
];

const Advantages: React.FC<Props> = ({ lang }) => {
  return (
    <div className='Advantages'>
      {AdvantagesData.items.map(({ title, text }: any, i: number) => (
        <div key={title[lang]} className='Advantages__item'>
          <div className='d-flex justify-content-center align-items-start mb-2'>
            {icons[i]}
          </div>
          <div className='Advantages__textTitle text-center mb-2'>
            {title[lang]}
          </div>
          <div className='text-center'>
            {(text[lang].split('\n') || []).map((str: string) => (
              <p key={str}>{str}</p>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Advantages;
