import React, { useRef } from 'react';
import { useDebounce, useLockScroll } from '../../hooks';
import cn from 'classnames';
import CloseIcon from '../../assets/close.svg';

import './Modal.scss';

interface iProps {
  isOpened: boolean;
  onClose: () => void;
  className?: string;
  children: React.ReactNode;
}

const Modal: React.FC<iProps> = ({
  isOpened,
  onClose,
  className,
  children
}) => {
  const modalRef = useRef(null);

  const debouncedIsOpened = useDebounce(isOpened, 200);
  const isModalOpened = isOpened || debouncedIsOpened;

  useLockScroll(isOpened);

  return isModalOpened ? (
    <div
      className={cn('Modal', className, {
        fadeIn: isOpened,
        fadeOut: !isOpened
      })}
      ref={modalRef}
      onClick={(e: React.MouseEvent) => {
        if (e.target === modalRef.current) {
          onClose();
        }
      }}
    >
      <div className='Modal__inner'>
        <div className='Modal__close'>
          <button onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className='Modal__content'>{children}</div>
      </div>
    </div>
  ) : null;
};

export default Modal;
