import React, { useEffect, useState } from 'react';
import ArrowRight from '../../assets/chevron-forward.svg';
import { PortalEnum } from '../../types';
import Portal from '../Portal/Portal';
import './Lift.scss';

const Lift = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (
        (window.scrollY ||
          window.pageYOffset ||
          document.documentElement.scrollTop) >= 1000
      ) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!isVisible) return null;

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };
  return (
    <Portal id={PortalEnum.Lift}>
      <button
        className='Lift position-fixed align-items-center justify-content-center d-flex'
        onClick={scrollTop}
      >
        <ArrowRight />
      </button>
    </Portal>
  );
};

export default Lift;
