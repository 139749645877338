import React, { useState } from 'react';
import TextField from '../TextField/TextField';
import cn from 'classnames';
import './Form.scss';
import { LangEnum } from '../../types';
import Localize from '../../constants/Localize.json';
import Notification from '../Notification/Notification';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { validateField, trimFormData } from './Form.helpers';

interface Props {
  className?: string;
  type?: 'full' | 'call';
  title?: string;
  lang: LangEnum;
  id?: string;
}

export interface FormData {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const initialFormData = {
  name: '',
  email: '',
  phone: '',
  message: ''
};

const initialTouched = {
  name: false,
  email: false,
  phone: false,
  message: false
};

const Form: React.FC<Props> = ({
  className,
  lang,
  id,
  type = 'full',
  title = Localize[lang].formTitle
}) => {
  const [data, setData] = useState<FormData>(initialFormData);
  const [touched, setTouched] = useState(initialTouched);
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [isNotificationShown, setIsNotificationShown] = useState(false);
  const [notificationText, setNotificationText] = useState('');

  const resetForm = () => {
    setTouched({
      name: false,
      email: false,
      phone: false,
      message: false
    });
    setData(initialFormData);
    setErrors({});
  };

  const closeNotification = () => {
    setIsNotificationShown(false);
  };

  const showNotification = (text: string, isError: boolean = false) => {
    setIsNotificationShown(true);
    setIsSubmitError(isError);
    setNotificationText(text);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const isFormValid =
      Object.entries(data)
        .map(([key, value]) =>
          validateField(key as keyof FormData, value, lang, setErrors)
        )
        .filter(v => !!v).length === 0;

    setTouched({
      name: true,
      email: true,
      phone: true,
      message: true
    });

    if (!isFormValid) {
      return;
    }

    setIsSubmitting(true);

    fetch('form.php', {
      method: 'POST',
      body: JSON.stringify({ ...trimFormData<FormData>(data), type })
    })
      .then(res => {
        if (res.statusText !== 'OK') throw new Error('Error');
        if (!res.ok) throw new Error('Error');
        trackCustomEvent({
          category: 'From submit',
          action: id || 'form submit'
        });
        showNotification(Localize[lang].successSubmit);
        resetForm();
      })
      .catch(() => {
        showNotification(Localize[lang].errorSubmit, true);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleBlur = (key: keyof FormData) => () => {
    !touched[key] && validateField(key, data[key], lang, setErrors);
    setTouched(prevState => ({ ...prevState, [key]: true }));
  };

  const handleChange = (key: keyof FormData) => (value: string) => {
    touched[key] && validateField(key, value, lang, setErrors);
    setData(prevState => ({ ...prevState, [key]: value }));
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={cn('Form', className)} id={id}>
        <div className='Form__title'>{title}</div>
        <div className='Form__content'>
          <TextField
            onChange={handleChange('name')}
            onBlur={handleBlur('name')}
            value={data.name}
            errorMessage={touched && errors.name}
            placeholder={Localize[lang].yourName}
            name='name'
          />
          {type === 'full' && (
            <TextField
              onChange={handleChange('email')}
              onBlur={handleBlur('email')}
              value={data.email}
              errorMessage={touched && errors.email}
              placeholder={Localize[lang].yourEmail}
              name='email'
            />
          )}
          <TextField
            onChange={handleChange('phone')}
            onBlur={handleBlur('phone')}
            value={data.phone}
            errorMessage={touched && errors.phone}
            placeholder={Localize[lang].yourPhone}
            name='phone'
          />
          {type === 'full' && (
            <TextField
              onChange={handleChange('message')}
              onBlur={handleBlur('message')}
              value={data.message}
              errorMessage={touched && errors.message}
              placeholder={Localize[lang].message}
              type='textarea'
              name='message'
            />
          )}
          <button type='submit' className='Form__btn' disabled={isSubmitting}>
            {Localize[lang].submit}
          </button>
        </div>
      </form>
      <Notification
        isShown={isNotificationShown}
        text={notificationText}
        isError={isSubmitError}
        onClose={closeNotification}
      />
    </>
  );
};

export default Form;
