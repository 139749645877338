import { useEffect } from 'react';

export const useLockScroll = (isOpened: boolean) => {
  useEffect(() => {
    const body = document.querySelector('body');
    isOpened
      ? (body.style.overflow = 'hidden')
      : (body.style.overflow = 'auto');
  }, [isOpened]);
};
