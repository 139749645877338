import React, { useState, useEffect, useRef } from 'react';
import Portal from '../Portal/Portal';
import PhoneIcon from '../../assets/call-outline.svg';
import Localize from '../../constants/Localize.json';
import './CallWidget.scss';
import Modal from '../Modal/Modal';
import { useDebounce } from '../../hooks';
import Form from '../Form/Form';
import { LangEnum, PortalEnum } from '../../types';
interface Props {
  lang: LangEnum;
}

const CallWidget: React.FC<Props> = ({ lang }) => {
  const btnRef = useRef(null);
  const [isOpened, setIsOpened] = useState(false);
  const debouncedIsOpened = useDebounce(isOpened, 200);
  const isModalOpened = isOpened || debouncedIsOpened;

  useEffect(() => {
    const animationInterval = setInterval(() => {
      if (!btnRef.current) return;
      (btnRef.current as HTMLButtonElement).classList.toggle(
        'CallWidget__icon--animated'
      );
    }, 5000);

    return () => {
      clearInterval(animationInterval);
    };
  }, []);

  return (
    <Portal id={PortalEnum.CallModal}>
      <div className='CallWidget'>
        {!isModalOpened ? (
          <button
            className='CallWidget__icon position-fixed d-flex align-items-center justify-content-center CallWidget__icon--animated'
            onClick={() => {
              setIsOpened(true);
            }}
            ref={btnRef}
          >
            <PhoneIcon />
          </button>
        ) : (
          <Modal
            isOpened={isOpened}
            onClose={() => {
              setIsOpened(false);
            }}
          >
            <Form
              className='Form--CallWidget pt-0'
              type='call'
              title={Localize[lang].orderCallback}
              lang={lang}
              id='call-form-modal'
            />
          </Modal>
        )}
      </div>
    </Portal>
  );
};

export default CallWidget;
