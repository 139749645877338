import { useState, useRef } from 'react';
import { useOnClickOutside } from './useOnClickOutside';

export const useSlideMenu = () => {
  const slideMenuRef = useRef<HTMLDivElement>(null);

  const [isSlideMenuOpened, setIsSlideMenuOpened] = useState(false);

  const closeSlideMenu = () => {
    setIsSlideMenuOpened(false);
  };

  const openSlideMenu = () => {
    setIsSlideMenuOpened(true);
  };

  useOnClickOutside(slideMenuRef, closeSlideMenu);

  return {
    slideMenuRef,
    closeSlideMenu,
    isSlideMenuOpened,
    openSlideMenu
  };
};
