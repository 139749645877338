import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

interface Props {
  id: string;
  children: ReactNode;
}

const Portal: React.FC<Props> = ({ id, children }) => {
  if (typeof document !== 'undefined') {
    return ReactDOM.createPortal(children, document.getElementById(id)!);
  }
  return null;
};

export default Portal;
