import React from 'react';
import './TextField.scss';
import cn from 'classnames';

interface Props {
  value: string;
  onChange: (v: string) => void;
  errorMessage?: string;
  onBlur: () => void;
  type?: 'input' | 'textarea';
  placeholder: string;
  name: string;
}

const TextField: React.FC<Props> = ({
  value,
  onChange,
  onBlur,
  type,
  errorMessage,
  placeholder,
  name
}) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange(e.target.value);
  };

  return (
    <div className='TextField position-relative'>
      {type === 'textarea' ? (
        <textarea
          onChange={handleChange}
          onBlur={onBlur}
          value={value}
          rows={6}
          placeholder={placeholder}
          className={cn('TextField__input', {
            TextField__input_error: !!errorMessage
          })}
          name={name}
        />
      ) : (
        <input
          type='text'
          onChange={handleChange}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
          className={cn('TextField__input p-2', {
            TextField__input_error: !!errorMessage
          })}
          name={name}
        />
      )}
      {errorMessage && (
        <div className='TextField__error w-100 position-absolute'>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default TextField;
