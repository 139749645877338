import React from 'react';
import './CallForm.scss';
import Form from '../Form/Form';
import { LangEnum } from '../../types';
import Localize from '../../constants/Localize.json';

interface Props {
  imgUrl: string;
  lang: LangEnum;
}

const CallForm: React.FC<Props> = ({ imgUrl, lang }) => {
  return (
    <section
      className='CallForm d-flex align-items-center'
      style={{
        backgroundImage: `linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6)), url('${imgUrl}')`,
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed'
      }}
    >
      <Form
        type='call'
        className='Form--CallForm width: 100% mx-auto'
        title={Localize[lang].orderCallback}
        lang={lang}
        id='call-form'
      />
    </section>
  );
};

export default CallForm;
